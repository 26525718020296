.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.authContainer {
  background: black !important;
  min-height: 100vh;
  display: block;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
  height:100%;

  .authWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    align-items: center;
    min-height:100vh;

    .authInner {
      width: 90vw;
      max-width:450px;
      margin: auto;
      background: #ffffff;
      box-shadow: 0 14px 80px rgba(34, 35, 58, 0.2);
      padding: 40px 55px 45px 55px;
      border-radius: 15px;
      transition: all .3s;

      h3 {
        text-align: center;
        margin: 0;
        line-height: 1;
        padding-bottom: 20px;
      }

      .formControl:focus {
        border-color: #167bff;
        box-shadow: none;
      }

      .custom-control-label {
        font-weight: 400;
      }

      .forgot-password,
      .forgot-password a {
        text-align: right;
        font-size: 13px;
        padding-top: 10px;
        color: #7f7d7d;
        margin: 0;
      }

      .forgot-password a {
        color: #167bff;
      }
    }
  }
}
