.itemBox {
  display: flex;
  flex-wrap: wrap;
  //width: 250px;
  height: 100px;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 7px;
  margin-top: 10px;
  margin-right: 30px;
  background-color: rgba(70, 100, 255, 0.15);

  .itemTop {
    margin-right: 15px;
  }

  .itemLeft {
    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
      margin-right: 15px;
    }

    div {
      width: 75px;
      height: 75px;
      border-radius: 75px;
      background-color: black;
    }
  }

  .divide {
    width: 2px;
    height: 55px;
    margin: 0 20px;
    background-color: rgba(0,0,0,0.1);
  }

  .itemRight {
    .itemTitle{
      font-size: 12px;
      font-weight: 400;
      text-align: left;
    }
    .itemContent {
      font-size: 18px;
      font-weight: 400;
      text-align: left;

      span {
        font-size: 25px;
        font-weight: 600;
        text-align: left;
        height: 34px;
      }

      .calQty {
        font-size: 18px;
        margin-left: 10px;
      }
      .calQty.Down {
        color: blue;
      }
      .calQty.UP {
        color: red;
      }
    }

    .topTitle {
      font-size: 25px;
      font-weight: 600;
    }
  }

  .borderCss {
    width: 2px;
    height: 80px;
    background-color: rgba(0,0,0,0.1);
    margin: 0 20px;
  }
}

@media (max-width: 767px) {
  .itemBox {
    height: fit-content;
    width: 100%;
    padding: 20px 0 0 20px;
    margin-right: 0;

    .itemTop {
      width: 100%;
      margin-bottom: 15px;
      text-align: center;
    }

    .itemLeft {
      width: 100%;
      margin-bottom: 15px;
      text-align: center;
    }

    .borderCss {
      display: none;
    }

    .itemRight {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .divide {
      display: none;
    }

  }
}
