.titleContainer {
  border-bottom: 1px solid black;
  width: 97%;
  margin-bottom: 20px;

  .titleItem{
    font-size: 18px;
    color: black;
    padding-bottom: 5px;
  }

  button {
    margin-left: 40px;
    width: 20px;
    height: 20px;
  }
}

.subTitleItem {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 25px;
  font-weight: 600;
}

.subTitleItem img {
  width: 13px;
  height: 13px;
}

.content_container {
  display: flex;
  flex-wrap: wrap;
}

.content_container .content_item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
  width: 47%;
  margin-right: 3%;
}

.content_container .item_title {
  width: 110px;
  color: black;
  font-size: 12px;
  margin-bottom: 5px;
  cursor: default;
}

.content_container .item_input {
  width: 100%;
  /*padding: 5px 0;*/
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  font-size: 15px;
  border-bottom: 1px solid grey;
  padding-left: 10px;
}

/*.content_container .item_input.not {*/
/*  cursor: not-allowed;*/
/*  background-color: #E9E9E9;*/
/*}*/

.buttons {
  display: flex;
  margin-top: 10px;
  float: right;
  padding: 5px 0;
  box-sizing: border-box;
  cursor: pointer;

  div {
    padding: 5px 25px;
    box-sizing: border-box;
  }

  .save_btn {
    border: 1px solid black;
    background-color: black;
    color: white;
  }

  .cancel_btn {
    margin-right: 15px;
    border: 1px solid black;
    color: black;
  }
}

.radio_box {

}

.radio_box label {
  margin-right: 20px;
  font-weight: 300;
}

.radio_box label input {
  margin: 0 5px 0 0 !important;
  width: 10px;
}

.ReactModal__Content .content_item .addressBtn {
  width: fit-content;
  background-color: black;
  color: white;
  padding: 4px;
  border-radius: 3px;
  font-size: 12px;
  position: absolute;
  right: 3px;
  cursor: pointer;
  top: 17px;
}

.input-datepicker {
  border: 1px solid black;
}

.input-datepicker.none {
  background-color: #E9E9E9;
  cursor: not-allowed;
}

.pagination>.active>a {
  z-index: 0;
}

.col-md-4, .col-md-6, .col-md-12 {
  padding-bottom: 10px;
  label {
    font-weight: 500;
  }
}
