.mainContainer {
  margin-left: 100px;
  padding: 0 50px 0 50px;
  box-sizing: border-box;
  width: calc(100% - 100px);

  .overview {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    padding-bottom: 40px;
    border-bottom: 5px solid #f8f8f8;

    .overviewTab {
      width: 100%;
      height: 41px;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      margin-top: 50px;

      .chartIconContainer {
        display: flex;

        .chart_icon {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          cursor: pointer;
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
      }

      .selectBox {
        margin-left: auto;
        font-size: 13px;
        font-weight: 500;
        width: 120px;
        padding-left: 10px;
        box-sizing: border-box;
        height: 30px;
        border-radius: 6px;
        border: none;
        background-color: #f8f8f8;
      }
    }

    .overviewContent {
      width: 100%;
      height: 400px;
      overflow: hidden;
      padding-top: 30px;

      .mapLegend {
        display: flex;
        cursor: default;
        //margin-left: 450px;
        margin-bottom: 5px;

        .legendItem {
          margin-right: 15px;

          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }

          .legend-icon-title {
            font-size: 12px;
          }
        }

      }
    }

    .overviewTitle {
      width: 100%;
      font-size: 20px;
      font-weight: 900;
      margin-bottom: 15px;
    }

    .layout_container {
      display: flex;
      flex-wrap: wrap;

      .chart_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        width: 350px;
        height: 350px;
        background-color: #f8f8f8;
        margin-top: 10px;
        margin-right: 10px;
        border-radius: 5px;
      }

      .layout_title {
        color: #000000;
        cursor: default;
        margin-top: 20px;
        width: fit-content;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
      }

      .graph-wrap {
        width: 100%;
        height: 300px;
        margin-top: 10px;
      }
    }

    .map-button-wrap {
      display: flex;
      margin: 10px 0;
      width: 100%;
      justify-content: space-between;

      .buttonWrap {
        display: flex;
        align-items: center;

        .robotCount {
          margin-left: 15px;
        }
      }

      button {
        margin-right: 10px;
      }

      button:focus {
        background-color: black;
        color: white;
      }
    }
  }
}


.risContainer {
  margin-left: 100px;
  padding: 0 50px 0 50px;
  box-sizing: border-box;
  width: calc(100% - 100px);

  .searchBox {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    align-items: center;

    .logoImg {
      width: 70px;
      height: 70px;
      margin-right: 10px;
      margin-bottom: 7px;
    }

    .searchTitle {
      font-size: 35px;
      margin-right: 50px;
      font-weight: 800;
    }
  }

  .overview {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;

    .overviewTab {
      width: 100%;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      margin-top: 50px;
      justify-content: space-between;

      .tab {
        width: 200px;
        cursor: pointer;
        height: 41px;
        padding: 10px 0;
        box-sizing: border-box;
      }

      .chartIconContainer {
        display: flex;

        .chart_icon {
          margin-right: 10px;
          cursor: pointer;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .overViewContent {
      width: 100%;
      height: 500px;
      overflow: hidden;
      padding-top: 30px;
    }

  }

}

.robotContainer {
  margin-left: 100px;
  padding: 0 50px 0 50px;
  box-sizing: border-box;
  width: calc(100% - 100px);

  .robotTab {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    height: 41px;
    border-bottom: 1px solid black;
    font-size: 18px;
    font-weight: 600;

    .tab {
      width: 200px;
      cursor: pointer;
      height: 41px;
      padding: 10px 0;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: -1px;
      color: #7e7e7e;
    }

    .tab.on {
      border-bottom: 3px solid #000000;
      color: black;
    }
  }

  .robotContent {
    height: fit-content;
  }

  .sortBox {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
    font-size: 12px;
    margin-top: 30px;

    .sortItem {
      float: right;
      width: fit-content;
      cursor: pointer;
    }

    .sortItem.on {
      font-weight: 700;
    }

    .sortOption {
      margin: 0 5px;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;

  div {
    border: 1px solid black;
    padding: 2px 20px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .cancelBtn {
    margin-right: 15px;
    border: 1px solid black;
    color: black;
  }

  .saveBtn {
    border: 1px solid black;
    background-color: black;
    color: white;
  }
}

.storeContainer {
  margin-left: 100px;
  padding: 0 50px 0 50px;
  box-sizing: border-box;
  width: calc(100% - 100px);
}

.pointContainer {
  margin-left: 100px;
  padding: 0 50px 0 50px;
  box-sizing: border-box;
  width: calc(100% - 100px);

  .robot-table-title {
    th select {
      border: none;
    }
  }

  .excelContent {
    display: flex;
    margin-left: 20px;
    align-items: center;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    div {
      font-size: 12px;
      text-decoration: underline;
      text-decoration-color: darkgrey;
      font-weight: 300;
    }
  }

  .pointTab {
    width: 200px;
    height: 41px;
    border-bottom: 3px solid black;
    font-size: 18px;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 30px;
    cursor: default;
    padding: 10px 0;
    box-sizing: border-box;
    text-align: center;
  }

  .pointAllContainer {
    margin-top: 50px;

    table {
      margin-bottom: 50px;
    }
  }

  .searchBox {
    display: flex;
    height: fit-content;
    flex-wrap: wrap;

    .searchInput {
      width: 300px;
      height: 35px;
      margin-right: 10px;
      padding-left: 10px;
      border-radius: 5px;
      border: 1px solid lightgrey;
    }

    .searchBtn {
      width: fit-content;
      height: 35px;
      background-color: black;
      color: white;
      padding: 8px 20px;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 15px;
    }

    .allCard {
      margin-top: 10px;
      font-size: 12px;
      color: gray;
      text-decoration: underline;
      cursor: pointer;
      align-self: flex-end;
    }
  }

  .contentTitle {
    display: flex;
    flex-wrap: wrap;
    font-size: 23px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;

    .cardInfo {
      display: flex;
      width: 400px;
      height: 35px;
      background-color: #f8f8f8;
      font-size: 14px;
      align-items: center;
      padding-left: 20px;

      div {
        font-weight: 300;
        margin-right: 10px;
      }

      span {
        margin-right: 20px;
      }
    }
  }

  .pointContent {
    margin-top: 50px;

    .contentDetail {
      .detailInfo {
        display: table;
        border-collapse: collapse;
        font-size: 15px;
        font-weight: 600;
        margin-top: 10px;
        width: 100%;
        margin-bottom: 25px;

        div {
          border: 1px solid black;
          display: table-cell;
          padding: 5px 30px;
        }
      }

      .pointUse {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;

        .pointInput {
          margin-right: 10px;
          border: 1px solid darkgrey;
          border-radius: 3px;
          width: 30%;
          min-width: 200px;
          height: 35px;
          padding-left: 10px;
        }

        .pointInput.On {
          border: 1px solid red;
        }

        .pointBtn {
          background-color: black;
          color: white;
          padding: 8px 10px;
          border-radius: 5px;
          cursor: pointer;
          margin-right: 10px;
          height: 35px;
        }

        .radio_box {
          width: 30%;
          min-width: 200px;
          label {
            margin-right: 10px;
          }

          input {
            margin-right: 5px;
          }
        }
      }

    }
  }
}


.settingsContainer {
  margin-left: 100px;
  padding: 0 50px 0 50px;
  box-sizing: border-box;
  width: calc(100% - 100px);

  .settingsTab {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    height: 41px;
    border-bottom: 1px solid black;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 50px;

    .tab {
      width: 200px;
      cursor: pointer;
      height: 41px;
      padding: 10px 0;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: -1px;
      color: #7e7e7e;
    }

    .tab.on {
      border-bottom: 3px solid #000000;
      color: black;
    }
  }

  //.buttonContainer {
  //  display: flex;
  //  justify-content: flex-end;
  //  align-items: center;
  //  margin-bottom: 50px;
  //
  //  div {
  //    border: 1px solid black;
  //    padding: 2px 20px;
  //    box-sizing: border-box;
  //    cursor: pointer;
  //  }
  //
  //  .cancelBtn {
  //    margin-right: 15px;
  //    border: 1px solid black;
  //    color: black;
  //  }
  //
  //  .saveBtn {
  //    border: 1px solid black;
  //    background-color: black;
  //    color: white;
  //  }
  //}

  .settingsContent {
    display: flex;

    .leftContent {
      min-width: 200px;
      height: 500px;
      border: 1px solid black;
      padding-left: 20px;
      padding-top: 15px;
      font-size: 12px;
      float: left;
      margin-right: 40px;

      .menu {
        margin-top: 10px;
        cursor: pointer;

        img {
          width: 10px;
          height: 10px;
          margin-right: 10px;
        }

        img:first-child {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }

        .topMenu {
          .newBtn {
            width: 16px;
            height: 16px;
            float: right;
          }
        }

        .subMenu {
          margin-top: 5px;
          margin-left: 30px;
          cursor: pointer;
        }
      }


    }

    .rightContent {
      width: 80%;
      height: inherit;

      .menuInfo {
        font-weight: 600;
        font-size: 15px;
      }

      .menuBody {
        height: inherit;
        margin-top: 10px;
        //background-color: yellow;
        border-top: 2px solid darkgrey;
        border-bottom: 2px solid darkgrey;

        .data {
          display: flex;
          height: fit-content;
          line-height: 40px;
          border-bottom: 1px solid darkgrey;

          .title {
            width: 150px;
            //background-color: greenyellow;
            padding-left: 10px;
            border-right: 1px solid darkgrey;
          }

          .content {
            padding-left: 10px;
            margin: 5px 10px;
            width: 40%;
            height: 30px;
          }

          .table tbody tr:hover {
            background-color: white;
          }
        }
      }

      .saveBtn {
        border: 1px solid black;
        padding: 2px 20px;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 3px;
        background-color: black;
        color: white;
        width: 70px;
        float: right;
        margin-top: 15px;
      }
    }
  }
}

.reviewContainer {
  margin-left: 100px;
  padding: 0 50px 0 50px;
  box-sizing: border-box;
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;

  .reviewOverview {
    width: 100%;
    height: fit-content;
    border-radius: 5px;
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;

    .reviewItem {
      margin-right: 30px;

      .reviewTitle {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .reviewContent {
        .reviewLabel {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        }
      }

    }
  }

  .reviewSearch {
    width: 100%;
    height: fit-content;
    border-radius: 5px;
    border: 1px solid black;
    padding: 10px;
  }

  .excel {
    display: flex;
    font-size: 16px;

    span {
      font-weight: 800;
      margin-right: 40px;
    }
    .download {
      font-size: 13px;
      text-decoration: underline;
      cursor: pointer;

      img {
        margin-right: 5px;
      }
    }
  }

  .reviewContent {
    height: fit-content;

    img {
      width: 30px;
      height: 30px;
    }
    tr {
      position: relative;

    }
    tr .tooltip {
      visibility: hidden;
      width: 120px;
      height: 50px;
      background-color: black;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;

      /* Position the tooltip text - see examples below! */
      position: absolute;
      z-index: 1;
    }

    tr:hover .tooltip {
      visibility: visible;
    }
  }

  .form-control[disabled] {
    cursor: default;
    background-color: white;
  }

  .commentContainer {
    width: 500px;
    height: 300px;
    background-color: white;
    border: 1px solid black;
  }

}

@media (max-width: 767px) {
  .mainContainer {
    width: 100%;
    margin-left: 0;
    padding: 0 25px 0 25px;

    .overview {
      .overviewTab {
        display: flex;
        flex-wrap: wrap;
        height: fit-content;
      }

      .map-button-wrap {
        .buttonWrap {
          flex-wrap: wrap;

          .robotCount {
            margin: 10px 0 0 0;
          }
        }
      }

      .layout_container {
        .chart_container {
          width: 100%;
          margin-right: 0;
        }
      }

      .overviewContent {
        .mapLegend {
          margin-left: 0;
          width: 100%;

          .legendItem {
            img {
              width: 15px;
              height: 15px;
            }

            .legend-icon-title {
              font-size: 10px;
            }
          }

        }
      }
    }

  }

  .risContainer {
    width: 100%;
    margin-left: 0;
    padding: 0 25px 0 25px;

    .searchBox {
      .react-datepicker-wrapper {
        width: 100px;
      }
    }
  }

  .robotContainer {
    width: 100%;
    margin-left: 0;
    padding: 0 25px 0 25px;

    .robotTab {
      .tab {
        width: fit-content;
        padding: 10px 20px;
        font-size: 16px;
      }
    }
  }

  .pointContainer {
    width: 100%;
    margin-left: 0;
    padding: 0 25px 0 25px;

    .contentTitle {
       font-size: 20px;
    }
    .pointContent {
      .contentDetail {
        .detailInfo {
          font-size: 12px;
          font-weight: 500;

          div {
            padding: 3px 5px;
          }
        }
      }
    }
  }

  .settingsContainer {
    width: 100%;
    margin-left: 0;
    padding: 0 25px 0 25px;

    .settingsTab {
      width: 100%;

      .tab {
        width: 100px;
        cursor: pointer;
        height: 41px;
        padding: 10px 0;
        font-size: 15px;
        box-sizing: border-box;
        text-align: center;
        margin-bottom: -1px;
        color: #7e7e7e;
      }
    }

    .settingsContent {
      flex-wrap: wrap;
      width: 100%;

      .leftContent {
        width: 100%;
        margin-right: 0;
        height: fit-content;
        padding-bottom: 20px;
        margin-bottom: 30px;
      }

      .rightContent {
        width: 100%;
      }
    }
  }

  .reviewContainer {
    width: 100%;
    margin-left: 0;
    padding: 0 25px 0 25px;

  }
}
