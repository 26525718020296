.map-wrap {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .map_detail_container {
    width: 600px;
    padding: 0 20px 10px 20px;
    box-sizing: initial;

    .map_detail_title {
      font-size: 18px;
      font-weight: 300;
      color: #898989;
      margin-bottom: 10px;

      span {
        font-weight: 500;
      }
    }

    .selectBox {
      border: none;
      font-size: 30px;
    }

    .robotName {
      font-size: 26px;
    }
    .branchName {
      font-size: 15px;
    }
    .mapGraph {
      width: 100%;
      height: 410px;
      margin-top: 10px;

      .lineTitle {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    .bottom_container {
      width: inherit;
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      .info_item {
        width: 150px;
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 10px 10px;
        border-radius: 7px;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .item_title {
          margin-bottom: 5px;
          font-size: 15px;
        }

        .divide {
          width: 128px;
          height: 1px;
          background-color: #d6d6d6;
          margin-bottom: 5px;
        }

        .item_content {
          font-size: 25px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .overviewContent {


    .map-wrap {
      .map_detail_container {
        width: 100%;
        padding: 0;

        .map_detail_title {
          font-size: 15px;
          margin-top: 5px;
          display: none;
        }

        .branchName {
          margin-top: 10px;
          font-size: 20px;
        }

        .bottom_container {
          flex-direction: column;
          margin-top: 5px;

          .info_item {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-right: 10px;
            margin-bottom: 10px;

            .item_title {
              font-size: 11px;
            }

            .divide {
              display: none;
            }

            .item_content {
              font-size: 20px;
            }
          }

        }

      }
    }

  }

}
