.menuMobile {
  width: 70%;
  height: 100%;
  top: 0;
  position: absolute;
  background-color: black;
  z-index: 100;
  overflow: hidden;

  .logo-layer {
    padding: 5px;

    .logo-icon{
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      img {
        height: 35px;
      }

      .closeBtn {
        color: white;
        margin-right: 10px;
        font-size: 30px;
      }
    }
  }

  .menu-layer {
    margin: 0 0 20px 15px;
  }
  .icon-title {
    color: white;
    font-size: 20px;
  }
  .icon-title.logout {
    font-size: 12px;
    margin-top: 70px;
    text-decoration: underline;
  }
}
