.searchBoxContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  align-items: center;

  .searchItem {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 50px;
    margin-top: 7px;

    input {
      width: 100%;
      height: 30px;
      margin: 0;
    }
    input:checked {
      background-color: black;
      border: none;
    }
    .selectTitle {
      width: 30px;
      margin-right: 10px;
    }
    .selectBox {
      width: 160px;
      height: 30px;
      padding: 0 0 0 10px;
      background-color: #f4f4f4;
      border: none;
    }
    .radioBox {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      label {
        margin: 0;
        display: flex;
        align-items: center;
        width: 80px;
      }
      button {
        margin-right: 10px;
      }
      button:focus {
        background-color: black;
        color: white;
      }
      button.on{
        background-color: black;
        color: white;
      }
      .radioBtn {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        padding: 0;
        margin-right: 5px;
      }
      .dateBox {
        display: flex;
        align-items: center;
      }
    }

  }

}

@media (max-width: 767px) {
  .searchBoxContainer{
    .searchItem {
      margin-right: 0;

      .selectTitle {
        min-width: fit-content;
      }
      .radioBox {

        .dateBox {
          margin-top: 7px;
        }
      }
    }
  }
}
