@import "theme";

.menuContainer {
  width: 100px;
  height: 100%;
  background-color: black;
  position: fixed;
  z-index: 100;

  .logo-layer {
    color: #000;
    cursor: default;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    height: 60px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;

    .logo-lounge {
      width: 52px;
      height: 37px;
      margin: 0;
      font-family: $font-family-en;
      font-size: 11.5px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }

    .logo-lab {
      font-size: 25px;
      font-family: $font-family-en;
      font-weight: 600;
      line-height: 0.96;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  .menuContent {
    overflow-y: auto;
    height: 100%;

    .menu-layer {
      width: 100px;
      height: 70px;
      background-color: $color-black;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      box-sizing: border-box;
      cursor: pointer;

      .menu-btn-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &.on {
        background-color: $color-white;
        color: $color-black;

        a {
          color: $color-black;
        }

        a:hover {
          color: inherit;
        }
      }

      a {
        color: inherit;
        display: inline-block;
      }

      &:not(.on) {
        a:hover {
          color: $color-white;
        }

        a {
          color: darkgray;
        }
      }

      .icon-wrap > span {
        font-size: 24px;

        &.icon-home {
          margin: 5px 2px 5px 4px;
        }
      }

      .icon-wrap > img {
        width: 28px;
        //height: 24px;
        filter: brightness(1) invert(0.5);
      }

      .menuIcon.On {
        filter: brightness(1) invert(0);
      }

      .icon-wrap {
        position: relative;
        display: inline-block;
      }
    }
  }
  .menuContent::-webkit-scrollbar {
    //width: 3px;
  }
  .menuContent::-webkit-scrollbar-thumb {
    //background-color: #2f3542;
  }
  .menuContent::-webkit-scrollbar-track {
    //background-color: grey;
  }
  .icon-title {
    line-height: 15px;
    margin-top: 6px;
    height: 15px;
    color: #7e7e7e;
  }

  .menu-layer:last-child {
    margin-top: auto;
  }

  .menu-layer.on {
    color: black;
  }

  .menu-layer:hover {
    .menuIcon {
      filter: brightness(1) invert(1);
    }

    .menuIcon.On {
      filter: brightness(1) invert(0);
    }

    .icon-title {
      color: white;
    }
  }

  .menu-layer.on:hover {
    .icon-title {
      color: gray;
    }
  }

  .icon-aris:before {
    content: "\e900";
  }

  .icon-baris:before {
    content: "\e901";
  }

  .icon-customer:before {
    content: "\e902";
  }

  .icon-home:before {
    content: "\e903";
  }
}

.headerContainer {
  width: 80%;
  height: fit-content;
  //background-color: blue;
  //position: fixed;
  margin-left: 100px;
  padding: 30px 0 30px 50px;
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 32px;
  //background-color: white;
}

.headerContainer.hidden {
  visibility: hidden;
}

.menuContainer.hidden {
  visibility: hidden;
}

.headerContainer span {
  font-weight: 700;
}

.footerContainer {
  width: 100%;
  height: 100px;
  background-color: yellow;
  position: fixed;
  margin-left: 100px;
  bottom: 0;
}

@media (max-width: 767px) {
  .headerContainer {
    margin-left: 0;
    padding: 15px 0 30px 25px;
    margin-bottom: 10px;
    height: fit-content;
    position: relative;

    .menuIcon {
      margin-bottom: 10px;
      visibility: visible;
    }
  }

  .menuContainer {
    display: none;
    width: 0;
  }
}


@media (min-width: 767px) {
  .headerContainer {
    .menuIcon {
      display: none;
    }
  }

}


@font-face {
  font-family: 'icomoon';
  src: url('../../assets/font/icomoon.eot?efysu0');
  src: url('../../assets/font/icomoon.eot?efysu0#iefix') format('embedded-opentype'),
  url('../../assets/font/icomoon.ttf?efysu0') format('truetype'),
  url('../../assets/font/icomoon.woff?efysu0') format('woff'),
  url('../../assets/font/icomoon.svg?efysu0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-confirm-alert {
  width: 300px;
  //height: 200px;
  background-color: white !important;
  box-shadow: 5px 4px 13px darkgrey;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .bg-danger .mx-2 {
    background-color: #D1312D;
    box-sizing: border-box;
    width: 300px;
    padding: 7px 10px;
    color: white;
  }

  .popup_min_height .card-title {
    padding: 20px 10px 30px 10px;
    border-bottom: 1px solid darkgrey;
  }

  .card-footer {
    float: right;
    padding-bottom: 7px;
    button {
      margin-right: 5px;
    }
  }
}
