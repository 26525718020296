$color-black: #000000;
$color-blue: #3e6fef;
$color-gray: #f2f5fa;
$color-navy: #2f2b7f;
$color-red: #f3546a;
$color-orange: #f9a536;
$color-green: #41c38f;
$color-yellow: #f7b801;
$color-white: #ffffff;
$color-gray-900: #212121;
$color-gray-800: #4b4b4b;
$color-gray-700: #646464;
$color-gray-600: #898989;
$color-gray-500: #b7b7b7;
$color-gray-400: #d0d0d0;
$color-gray-300: #e0e0e0;
$color-gray-200: #efefef;
$color-gray-100: #fafafa;
$color-primary: #000000;
$color-secondary: #313133;
$color-success: $color-blue;
$color-danger: $color-red;
$color-warning: $color-orange;
$color-info: #3c3d3f;
$color-light: #fafdf2;
$color-dark: $color-gray-900;

$color-body-bg: $color-secondary;
$color-body-color: #f7f6fa;
$color-link: #989898;

$spacer: 1rem;

$font-size-xl: 1.5rem;
$font-size-lg: 1.125rem; // 18px
$font-size-base: 1rem; // 16px
$font-size-sm: 0.9375rem; // 15px
$font-size-xs: 0.875rem; // 14px
$font-size-xxs: 0.75rem; // 12px

$font-weight-bold: 700;
$font-weight-normal: 400;
$font-weight-thin: 300;

$border-radius-xs: 3px;
$border-radius-sm: 5px;
$border-radius-md: 10px;

// Default Layout
//$default-layout-left-width: $spacer * 13.4375;
$default-layout-left-width: 100px;

// Tool Layout
$tool-layout-min-width: 1140px;
$tool-layout-header-height: $spacer * 4.75;
$tool-layout-footer-height: $spacer * 4.5;
$tool-layout-left-width: $spacer * 4.0625;
$tool-layout-column-default-width: $spacer * 12.5;
$tool-layout-column-wide-width: $spacer * 17.5;

// Job Overview Layout
$job-overview-layout-width: 1140px;
$job-overview-layout-header-height: $spacer * 5;
$job-overview-layout-footer-height: $spacer * 5;
$job-overview-layout-side-width: $spacer * 4;
$job-overview-layout-column-default-width: $spacer * 19;
$job-overview-layout-column-height: $spacer * 38;

// Subtask Detail Layout
$subtask-detail-layout-header-height: $spacer * 4.875;

$layout-padding: 23px;

$font-family-en: 'Montserrat';
$font-family-ko: 'Spoqa Han Sans Neo';
