.table {
  width: 100%;
}

.table.table-border {
  border: 1px solid #eee;
}

.table tr td,
.table tr th {
  padding: 10px 15px;
  vertical-align: middle !important;
  cursor: default;
}

.table tbody {
  font-weight: 500;
}

.table.table-fit tr td,
.table.table-fit tr th {
  padding: 10px 0px;
}

.table.table-border tr td,
.table.table-border tr th {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}

.table tr {
  height: 46px;
}

.table tr th {
  font-weight: 400;
  text-align: center;
}
.table thead>tr>th {
  border-bottom: 1px solid black;
}

.table tbody tr:hover {
  background-color: #F0F0F0;
}

.table.checkout tr td {
  vertical-align: top !important;
  padding: 15px;;
}

.table.checkout tr td:first-child,
.table.checkout tr th:first-child {
  max-width: 60px;
}

.table.checkout tr td:last-child,
.table.checkout tr th:last-child {
  text-align: center;
}

.table.checkout small {
  display: block;
}
